<template lang="pug">
  .root
    component-header-index

    div(class="container-flex")
      div(class="content-info")
        div(class="news-header") >>
          span(class="display-title") お知らせ
        div(class="newsList")
          div(v-for="item in $store.state.news.list" class="news-item")
            a(@click="onRead(item.id)")
              i.fas.fa-circle(v-if="!item.readed" style="color: red; position: absolute")
              div(class="news-item-img")
                img(v-if="item.banner_store_path" :src="getBannerUrl(item.banner_store_path)")
              div(class="news-item-detail")
                div(class="news-item-detail-title") {{ item.title }}
                div.news-item-detail-comment {{ToText(item.describe)}}
                div(class="news-item-detail-day")
                  div(class="follow-account-name-date pc") 更新日時 {{ new Date(item.public_time).getTime() | moment("YYYY/MM/DD HH:mm") }}

      div
        div(v-if="!ui.showPanel")
          pr-banner
        div(v-if="ui.showPanel")
          div.right-content-border
            button.close-button.build-new-room-content-close(@click="ui.showPanel = false;") X
            div.select-news-info
              h5 {{ oneNewsInfo.title }}
              h5 <i class="far fa-clock"></i> {{ oneNewsInfo.public_time | moment("YYYY.MM.DD HH:mm") }}
            div.select-news-img
              img.rounded.img-list(:src="$store.state.system.config.storage_addr_list.public + oneNewsInfo.banner_store_path" alt="")
            div(class="select-news-text" v-html="oneNewsInfo.describe")

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getImageUrl } from '@/utils/tools'

export default {
  name: "NewsIndex",
  components: {},
  data() {
    return {
      ui: {
        showPanel: ''
      },
      oneNewsInfo: {

      }
    }
  },
  props: {},
  computed: {},
  watch: {},
  beforeMount() {},
  mounted() {},
  async created() {
    await this.$store.dispatch('news/syncNewsList');
  },
  updated() {},
  destroyed() {},
  methods: {
    async onRead(id) {
      this.ui.showPanel = true;
      this.oneNewsInfo = await this.$store.dispatch('news/readNews', id);
      await this.$store.dispatch('news/syncNewsList');
    },
    getBannerUrl(url){
      return getImageUrl(url)
    },
    ToText(HTML){
      var input = HTML;
      return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');
    }
  }
};
</script>
